import _ from "lodash"
import React from "react"
import { Button } from ".."

export const CryptoPad = (props: CryptoPadProps) => {

    return (
        <div className="crypto-pad w-[340px] grid grid-cols-4 gap-2 px-5 py-12 bg-white rounded-[10px]">
            <Button label="1" className="border-primary" onClick={() => props.onClick?.("1")} />
            <Button label="2" className="border-primary" onClick={() => props.onClick?.("2")} />
            <Button label="3" className="border-primary" onClick={() => props.onClick?.("3")} />
            <Button className="!border-default" onClick={() => props.onClick?.("-")}>
                <img src="/assets/img/icons/minus.svg" className="w-3" />
            </Button>
            <Button label="4" className="border-primary" onClick={() => props.onClick?.("4")} />
            <Button label="5" className="border-primary" onClick={() => props.onClick?.("5")} />
            <Button label="6" className="border-primary" onClick={() => props.onClick?.("6")} />
            <Button className="!border-default" onClick={() => props.onClick?.(" ")}>
                <img src="/assets/img/icons/space.svg" className="w-5" />
            </Button>
            <Button label="7" className="border-primary" onClick={() => props.onClick?.("7")} />
            <Button label="8" className="border-primary" onClick={() => props.onClick?.("8")} />
            <Button label="9" className="border-primary" onClick={() => props.onClick?.("9")} />
            <Button className="!border-default" onClick={() => props.onClick?.("\b")}>
                <img src="/assets/img/icons/backspace.svg" className="h-6" />
            </Button>
            <Button className="!border-default" onClick={() => props.onClick?.("-")}>
                <img src="/assets/img/icons/slash.svg" />
            </Button>
            <Button label="0" className="border-primary" onClick={() => props.onClick?.("0")} />
            <Button label="." className="!border-default" onClick={() => props.onClick?.(".")} />
            <Button className="!border-primary !bg-primary" onClick={() => props.onClick?.("*")}>
                <img src="/assets/img/icons/check-white.svg" className="h-5" />
            </Button>
        </div>
    )
}

export type CryptoPadProps = {
    onClick?: (value: string) => void
}

