import { AppRouteProps } from '@vadato/component'
import React from 'react'
import { HomeAccessLayer } from './containers/HomeAccessLayer'

const Home = React.lazy(() => import('./containers/Home'))

export const HomeRoutes: Array<AppRouteProps> = [
    { 
        path: '', 
        element: <HomeAccessLayer />,
        children: [
            { 
                path: '', 
                element: <Home />,
            }
        ]
    }
]
