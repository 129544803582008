import { useTranslations } from "@hooks/translation"
import React from "react"
import { ButtonSubmit, CryptoCurrency } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"

export const CreationDonationForm = (props: CreationDonationFormProps) => {

    const [
        SELECT_CRYPTO,
        SEND,
    ] = useTranslations(i18n)
    const { formData, handleInputChange, getError } = useCreationFormData()

    return (
        <div className="creation-form-container flex flex-col gap-7">
            <div className="flex flex-col gap-2 m-auto w-[320px]">
                <CryptoCurrency
                    error={getError('amount') as string}
                    value={{
                        currency: formData?.crypto_coin,
                        value: formData?.amount?.toString(),
                    }}
                    onChange={(value) => {
                        handleInputChange('crypto_coin', value?.currency)
                        handleInputChange('amount', value?.value)
                    }}
                />
            </div>
            {
                !props.hideSubmit &&
                <div className="justify-center hidden md:flex">
                    <ButtonSubmit
                        className="btn-primary font-bold text-black"
                        label={SEND}
                        isSubmit={props.isSubmit}
                        onClick={props.onSubmitClick}
                    />
                </div>
            }
        </div>
    )
}

export type CreationDonationFormProps = {
    hideSubmit?: boolean
    isSubmit?: boolean
    onSubmitClick?: () => void
}


const i18n = [
    'Vadato.Donators.Create.Form.SelectCrypto.Label',
    'Vadato.Donators.Create.Form.Send.Label',
]
