import _ from "lodash"
import { useCallback } from "react"

export const useFormDataInputProps = ({
    formData,
    validateOnBlur,
    validateOnKeyUp,
    getError,
    partialEdit,
    onChange,
    isEditing,
    toggleEditing,
    validateControl
}: DataInputPropsParams) => {

    return useCallback((name: string) => {
        const value = _.get(formData, name)
        return {
            value,
            error: getError(name) as string,
            onChange: (value: any) => onChange?.(name, value),
            partialEdit,
            partialEditing: isEditing(name),
            onPartialEditClick: () => toggleEditing(name),
            onBlur: () => {
                if (validateOnBlur) {
                    validateControl(name)
                }
            },
            onKeyUp: () => {
                if (validateOnKeyUp) {
                    validateControl(name)
                }
            }
        }
    }, [
        formData,
        validateOnBlur,
        getError,
        partialEdit,
        onChange,
        isEditing,
        toggleEditing,
        validateControl
    ])
}

export type DataInputPropsParams = {
    formData: any
    partialEdit?: boolean
    validateOnBlur?: boolean
    validateOnKeyUp?: boolean
    onChange?: (key: string, value: any) => void
    getError: (name: string) => React.ReactNode
    isEditing: (name: string) => boolean
    toggleEditing: (name: string) => void
    validateControl: (name: string) => void
}
