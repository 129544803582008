import { useTranslations } from "@hooks/translation";
import { useMetaMask } from "metamask-react/lib/use-metamask";
import React from "react";
import { useCallback, useEffect, useMemo } from "react";
import { Button } from "..";

export const MetamaskButton = (props: MetamaskButtonProps) => {

    const [
        WALLET_BUTTON,
        INITIALIZING_LABEL,
        UNAVAILABLE_LABEL,
        NOTCONNECTED_LABEL,
        CONNECTING_LABEL,
        CONNECTED_LABEL,
    ] = useTranslations(i18n)

    const { status, connect, account, chainId, ethereum } = useMetaMask();

    useEffect(() => {
        if (status === 'connected') {
            props.onChange?.(account)
        }
    }, [status, account])

    const message = useMemo(() => {
        const dict: Record<string, string> = {
            'initializing': INITIALIZING_LABEL,
            'unavailable': UNAVAILABLE_LABEL,
            'connected': account!
        }
        return dict[status as string]
    }, [status, account, chainId])

    const handleConnectClick = useCallback(() => {
        connect()
    }, [])

    if (['initializing', 'unavailable', 'connected'].includes(status)) {
        return (
            <p className="font-bold">{message}</p>
        )
    }

    return (
        <Button 
            className="btn-primary rounded-full" 
            onClick={handleConnectClick}
            label={
                status === 'connecting' 
                ? CONNECTING_LABEL 
                : status === 'notConnected'
                    ? WALLET_BUTTON
                    : ''
            } 
            />
    )
}

export type MetamaskButtonProps = {
    onChange?: (value: string) => void
}

const i18n = [
    'Vadato.Donators.Create.Form.Connect.Wallet.Button.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Status.initializing.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Status.unavailable.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Status.notConnected.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Status.connecting.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Status.connected.Label',
]
