import { useTranslations } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import { getCode, getName } from "country-list"
import React from "react"
import { Checkbox, DatePicker, GeoCodingInput, Input, Nationality, PhoneNumberInput } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"

export const CreationDonatorForm = () => {

    const [
        TITLE,
        CIVILTY,
        CIVILTY_MISTER,
        CIVILTY_MADAME,
        LASTNAME,
        FIRSTNAME,
        BIRTHDAY,
        PLACEOFBIRTH_LABEL,
        RECEIVEEMAIL_LABEL,
        _1,
        NATIONALITY,
        ADRESS,
        POSTAL_CODE,
        CITY,
        EMAIL,
        PHONE,
    ] = useTranslations(i18n)

    const { md } = useBreakpoints()
    const { formData, handleInputChange, getError } = useCreationFormData()

    return (
        <div className="creation-form-container flex flex-col gap-7">
            <h4 className="font-bold">{TITLE}</h4>

            <div className="flex flex-col gap-7">
                {
                    !md &&
                    <>
                        <div className="gap-5 flex">
                            <div className="flex-[0.2]">
                                <Input
                                    label={CIVILTY}
                                    value={formData.title}
                                    suggestions={[
                                        { label: 'M.', value: 'M.' },
                                        { label: 'Mme.', value: 'Mme.' },
                                        { label: 'Mlle.', value: 'Mlle.' },
                                    ]}
                                    error={getError('title') as string}
                                    onChange={(val) => handleInputChange('title', val)}
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={LASTNAME}
                                    value={formData.last_name}
                                    error={getError('last_name') as string}
                                    onChange={(val) => handleInputChange('last_name', val)}
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={FIRSTNAME}
                                    value={formData.first_name}
                                    error={getError('first_name') as string}
                                    onChange={(val) => handleInputChange('first_name', val)}
                                />
                            </div>
                        </div>
                        <div className="gap-4 flex">
                            <div className="flex-[0.2]">
                                <DatePicker
                                    label={BIRTHDAY}
                                    value={formData.dob}
                                    error={getError('dob') as string}
                                    showMonthYearDropdown
                                    onChange={(val) => handleInputChange('dob', val)}
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Input
                                    label={PLACEOFBIRTH_LABEL}
                                    value={formData.place_of_birth}
                                    error={getError('place_of_birth') as string}
                                    onChange={(val) => handleInputChange('place_of_birth', val)}
                                />
                            </div>
                            <div className="flex-[0.4]">
                                <Nationality
                                    label={NATIONALITY}
                                    value={getCode(formData?.nationality || 'FR')}
                                    error={getError('nationality') as string}
                                    onChange={(value) => handleInputChange('nationality', getName(value))}
                                />
                            </div>
                        </div>
                    </>
                }

                {/* Mobile displaying */}
                {
                    md &&
                    <>
                        <div className="flex gap-5">
                            <div className="flex-[0.3]">
                                <Input
                                    label={CIVILTY}
                                    value={formData.title}
                                    error={getError('title') as string}
                                    onChange={(val) => handleInputChange('title', val)}
                                />
                            </div>
                            <div className="flex-[0.7]">
                                <Input
                                    label={LASTNAME}
                                    value={formData.last_name}
                                    error={getError('last_name') as string}
                                    onChange={(val) => handleInputChange('last_name', val)}
                                />
                            </div>
                        </div>
                        <div className="flex flex-col gap-2  md:hidden">
                            <div className="flex-1">
                                <Input
                                    label={FIRSTNAME}
                                    value={formData.first_name}
                                    error={getError('first_name') as string}
                                    onChange={(val) => handleInputChange('first_name', val)}
                                />
                            </div>
                            <div className="flex-1">
                                <div className="flex gap-5">
                                    <DatePicker
                                        label={BIRTHDAY}
                                        value={formData.dob}
                                        error={getError('dob') as string}
                                        onChange={(val) => handleInputChange('dob', val)}
                                    />
                                    <Input
                                        label={PLACEOFBIRTH_LABEL}
                                        value={formData.place_of_birth}
                                        error={getError('place_of_birth') as string}
                                        onChange={(val) => handleInputChange('place_of_birth', val)}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-5 ">
                            <Nationality
                                label={NATIONALITY}
                                value={getCode(formData?.nationality || 'FR')}
                                error={getError('nationality') as string}
                                onChange={(value) => handleInputChange('nationality', getName(value))}
                            />
                        </div>
                    </>
                }



                {/* Mobile displaying end */}

                <div>
                    <GeoCodingInput
                        label={ADRESS}
                        value={formData?.address?.address}
                        error={getError('address.address') as string}
                        onResult={(result) => {
                            handleInputChange('address.postal_code', result?.zipCode)
                            handleInputChange('address.city', result?.city)
                        }}
                        onChange={(value) => handleInputChange('address.address', value)}
                    />
                </div>

                <div className="flex gap-4">
                    <div className="flex-[0.4]">
                        <Input
                            label={POSTAL_CODE}
                            value={formData?.address?.postal_code}
                            error={getError('address.postal_code') as string}
                        />
                    </div>
                    <div className="flex-[0.6]">
                        <Input
                            label={CITY}
                            value={formData?.address?.city}
                            error={getError('address.city') as string}
                        />
                    </div>
                </div>

                <div>
                    <Input
                        label={EMAIL}
                        value={formData?.email}
                        error={getError('email') as string}
                        onChange={(value) => handleInputChange('email', value)}
                    />
                </div>

                <div>
                    <PhoneNumberInput
                        label={PHONE}
                        value={formData?.phone}
                        error={getError('phone') as string}
                        onChange={(value, countryCode) => {
                            handleInputChange('phone', value)
                            handleInputChange('country_code', countryCode)
                        }}
                    />
                </div>

                <div>
                    <Checkbox
                        options={[
                            { label: RECEIVEEMAIL_LABEL, value: true }
                        ]}
                        value={formData.receive_email}
                        onChange={(value) => handleInputChange('receive_email', value)}
                    />
                </div>
            </div>
        </div>
    )
}

const i18n = [
    'Vadato.Donators.Create.Form.Title',
    'Vadato.Donators.Create.Form.Civilty.Label',
    'Vadato.Donators.Create.Form.Civilty.Mister.Label',
    'Vadato.Donators.Create.Form.Civilty.Madame.Label',
    'Vadato.Donators.Create.Form.LastName.Label',
    'Vadato.Donators.Create.Form.FirstName.Label',
    'Vadato.Donators.Create.Form.BirthDay.Label',
    'Vadato.Donators.Create.Form.PlaceOfBirth.Label',
    "Vadato.Donators.Create.Form.ReceiveEmail.Label",
    'Vadato.Donators.Create.Form.BirthDay.Validation.Error',
    'Vadato.Donators.Create.Form.Nationality.Label',
    'Vadato.Donators.Create.Form.Adress.Label',
    'Vadato.Donators.Create.Form.PostalCode.Label',
    'Vadato.Donators.Create.Form.City.Label',
    'Vadato.Donators.Create.Form.Email.Label',
    'Vadato.Donators.Create.Form.Phone.Label',
]
