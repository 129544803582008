import { ReactNode } from "react";
import { atom, atomFamily, RecoilState } from "recoil";

const atoms: Record<string, any> = {}

export const FormDataState = (id: string, defaultValue?: any): RecoilState<any> => {
    if (!atoms[id]) {
        atoms[id] = atom<any>({
            key: `state.FormDataState.${id}`,
            default: defaultValue || {}
        })
    }
    return atoms[id]
}

export const FormDataErrorState = atomFamily<FormDataErrorProps, string>({
    key: 'state.FormDataErrorState',
    default: {},
});

export type FormDataErrorProps = Record<string, string | ReactNode>
