import { AppRouteProps } from "@vadato/component";
import React from "react";
import { HomeRoutes } from "./home/HomeRoutes";
import MainLayout from "components/layout/MainLayout";

export const VadatoRoutes: Array<AppRouteProps> = [
    {
        path: '',
        element: <MainLayout />,
        children: [
            ...HomeRoutes
        ]
    }
]