import React, { createRef, HTMLAttributes, useCallback, useEffect, useMemo } from "react"
import { BaseControl, BaseControlProps } from "./BaseControl"
import { useGoogleMapsInit } from "./hooks/useGoogleMapsInit"

export const GeoCodingInput = ({ searchLimit, ...props }: GeoCodingInputProps) => {

    const initialized = useGoogleMapsInit()
    const inputRef = createRef<HTMLInputElement>()

    const options = useMemo(() => {
        return (
            {
                ...(!!searchLimit ? { bounds: searchLimit, strictBounds: true } : {}),
                fields: ["address_components", "geometry", "icon", "name"],
                language: 'fr'
            }
        )
    }, [searchLimit])

    useEffect(() => {
        if (initialized && !!(window as any).google) {
            const autocomplete = new (window as any).google.maps.places.Autocomplete(
                inputRef.current,
                options
            );
            
            autocomplete.addListener("place_changed", async function () {
                const place = await autocomplete.getPlace();
                const results: TPlaceResults = {}
                results.latitude = place.geometry.location.lat()
                results.longitude = place.geometry.location.lng()
                results.address = place?.name
                for (let i = 0; i < place.address_components.length; i++) {
                    const addressType = place.address_components[i].types[0];
                    if (addressType === 'street_number') {
                        results.streetNumber = place.address_components[i].long_name
                    }
                    if (addressType === 'route') {
                        results.address = place.address_components[i].long_name
                    }
                    if (addressType === 'postal_code') {
                        results.zipCode = place.address_components[i].long_name
                    }
                    if (addressType === 'locality') {
                        results.city = place.address_components[i].long_name
                        // hard coded because google maps doesn't return this information
                        results.region = results.city
                    }
                    if (addressType === 'country') {
                        results.country = place.address_components[i].long_name
                    }
                }
                
                props.onResult?.(results)
                props.onChange?.(placeResultToString(results));
            });
        }
    }, [initialized, (window as any).google])

    const placeResultToString = useCallback((result: TPlaceResults) => {
        return (result.streetNumber || '') + (result.streetNumber ? ', ' : '')
            + (result.address ? `${result.address} ` : '')
            + (result.zipCode ? `${result.zipCode} ` : '')
            + (result.city ? `${result.city} ` : '')
            + (result.country && result.address !== result.country ? `${result.country} ` : '')
    }, [])

    return (
        <BaseControl {...props}>
            <div className="form-group">
                <input
                    {...props}
                    value={props.value}
                    type="text"
                    data-testid={`control-${props.name}`}
                    className="form-control"
                    onChange={(e) => props.onChange?.(e.target.value)}
                    ref={inputRef}
                />
            </div>
        </BaseControl>
    )
}

export type GeoCodingInputProps = BaseControlProps & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    searchLimit?: string
    value?: string
    onChange?: (value: string) => void
    onResult?: (value?: TPlaceResults) => void
}


export type TPlaceResults = {
    city?: string
    country?: string
    zipCode?: string
    address?: string
    region?: string
    longitude?: number
    latitude?: number
    streetNumber?: string
}
