import React, { createRef, useEffect } from "react"
import { getCode,  getNames } from "country-list"
import { Button } from "@components/button"

export const CountryList = (props: CountryListProps) => {

    const containerRef = createRef<HTMLDivElement>()
    useEffect(() => {
        containerRef.current?.scrollTo({
            top: document.getElementById(`country-${props.value}`)?.offsetTop,
        })
    }, [])

    return (
        <div 
            className="flex flex-col gap-1 bg-white rounded-[10px] overflow-hidden"
            ref={containerRef}>
            {
                getNames().sort((a, b) => a > b ? 1 : -1).map((name, index) => {
                    const code = getCode(name)
                    return (
                        <Button
                            key={index}
                            id={`country-${code}`}
                            className={`px-2 py-2 max-w-[320px] text-left !justify-start ${props.value === code ? '!bg-default' : ''}`}
                            startIcon={<img src={`https://cdn.ipregistry.co/flags/wikimedia/${code?.toLocaleLowerCase()}.png`} className="w-8" />}
                            onClick={() => props.onClick?.(code!)}
                            label={name}
                        />
                    )
                })
            }
        </div>
    )
}

export type CountryListProps = {
    value?: string
    onClick?: (code: string) => void
}

