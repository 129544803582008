import React, { HTMLAttributes } from "react"
import ReactDatePicker, { ReactDatePickerProps } from "react-datepicker"
import { BaseControl, BaseControlProps } from "./BaseControl"
import moment from 'moment'
import "react-datepicker/dist/react-datepicker.css";
import { DateRangeButtonInput } from "./DateRangeButtonInput"

export const DatePicker = ({ readonly, value, ...props }: DatePickerProps) => {

    return (
        <BaseControl {...props} className={`control-date ${props.className}`}>
            <ReactDatePicker
                selected={moment(value).toDate()}
                selectsStart
                customInput={<DateRangeButtonInput />}
                {...props}
                onChange={(date) => props.onChange?.(moment(date).format('YYYY-MM-DD'))}
            />
        </BaseControl>
    )
}

export type DatePickerProps = BaseControlProps & Omit<ReactDatePickerProps, 'error'> & Omit<HTMLAttributes<HTMLInputElement>, 'onChange'> & {
    value?: string
    onChange?: (value: string) => void
}
