import React from "react"

export const BaseControlSuccess = (props: BaseControlSuccessProps) => {

    return (
        <div className="flex w-full justify-between py-2">
            <div className="flex items-center gap-2 text-success">
                {
                    props.success &&
                    <>
                        <img
                            src="/assets/img/icons/check-circle.png"
                            loading="lazy"
                            width={20}
                            alt=""
                        />
                        <span className="text-sm">{props.success}</span>
                    </>
                }
            </div>
            {
                props.indication &&
                <span className="text-sm">{props.indication}</span>
            }
        </div>
    )
}

export type BaseControlSuccessProps = {
    name?: string
    success?: string
    indication?: string
}
