import { TAssociationCreate } from "@hooks/data"
import { useTranslations } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import React from "react"
import { useState } from "react"
import { Button } from ".."
import { useAssociationColorValue } from "./hooks/useAssociationColorValue"

export const AssociationCreationDescription = (props: DonatorCreationDescriptionProps) => {

    const [
        CREATE_TITLE,
        CREATE_SUBTITLE,
        DESCRIPTION_TITLE,
        DESCRIPTION_CONTENT1,
        DESCRIPTION_CONTENT2,
        DESCRIPTION_WEBSITE,
        DESCRIPTION_WEBSITE_VALUE,
        CONTINUE
    ] = useTranslations(i18n)

    const { md } = useBreakpoints()
    const [isMinimized, setIsMinimized] = useState(props.minimized)
    const {
		backgroundColor,
		borderColor,
		buttonColor,
		buttonTextColor,
		nameColor,
		titleColor
	} = useAssociationColorValue(props.color_code)

    return (
        <div className={`
            bg-white w-full
            ${md ? 'fixed top-0 left-0 right-0  z-50' : 'relative inset-[unset]' }
            ${isMinimized ? 'h-[100px] rounded-b-[40px] shadow-lg' : `${md ? 'h-screen' : 'h-auto'}`}
            overflow-auto overflow-x-hidden md:overflow-visible
            transition-all
        `}
            style={{ transitionDuration: '500ms' }}
        >
            {
                !isMinimized &&
                <img
                    src={props.bg_img}
                    className="object-cover w-full h-[200px] md:h-[380px]"
                />
            }

            <div className="py-5 px-7 md:px-12 md:py-20 ">
                <div className="flex gap-4" onClick={() => setIsMinimized(false)}>
                    <img src={props.logo} className="w-[48px] md:w-[96px] object-contain" />
                    <div>
                        <h2 style={{ color: nameColor }}>{props.first_name}</h2>
                        <h3 className="" style={{ color: titleColor }}>{props.title}</h3>
                    </div>
                </div>
                {
                    !isMinimized &&
                    <>
                        <div className="mt-10 flex flex-col gap-5">
                            <div dangerouslySetInnerHTML={{ __html: props.description || '' }} />
                            <p className="mt-5 text-right underline text-[12px] md:text-[16px]">
                                <span>{DESCRIPTION_WEBSITE}</span> <a href={DESCRIPTION_WEBSITE_VALUE} target="_blank">{props.website}</a>
                            </p>
                        </div>
                        <div className="md:hidden mt-10 mb-5">
                            <Button 
                                className="btn-primary w-full text-center justify-center" 
                                style={{ backgroundColor: buttonColor, color: buttonTextColor }}
                                onClick={() => setIsMinimized(true)}>
                                {CONTINUE}
                            </Button>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}

const i18n = [
    "Vadato.Donators.Create.Title",
    "Vadato.Donators.Create.Subtitle",
    "Vadato.Donators.Create.Description.Title",
    "Vadato.Donators.Create.Description.Content1",
    "Vadato.Donators.Create.Description.Content2",
    "Vadato.Donators.Create.Description.Website",
    "Vadato.Donators.Create.Description.Website.Value",
    "Vadato.Donators.Create.Continue.Button.Label",
]

export type DonatorCreationDescriptionProps = TAssociationCreate & {
    minimized: boolean
    onContinueClick?: () => void
}
