import { useTranslations } from "@hooks/translation"
import { useMetaMask } from "metamask-react/lib/use-metamask"
import React from "react"
import { Input } from ".."
import { useCreationFormData } from "./hooks/useCreationFormData"
import { MetamaskButton } from "./MetamaskButton"

export const CreationWalletForm = () => {

    const [
        WALLET_LABEL,
        WALLET_BUTTON,
        WALLET_OR,
        WALLET_PUBLICKEY,
    ] = useTranslations(i18n)
    const { formData, handleInputChange, getError } = useCreationFormData()
    const { status, connect, account, chainId, ethereum } = useMetaMask();

    return (
        <div className="creation-form-container flex flex-col gap-5">

            <div className="flex flex-col md:flex-row gap-4 items-center">
                <p>{WALLET_LABEL}</p>
                <MetamaskButton
                    onChange={(value) => handleInputChange('wallet_address', value)}
                />
            </div>
            {
                status !== 'connected' &&
                <div className="flex flex-col md:flex-row gap-4 items-center">
                    <p>{WALLET_OR}</p>
                    <p>{WALLET_PUBLICKEY}</p>
                    <Input
                        className="w-full md:w-auto"
                        value={formData?.wallet_address}
                        error={getError('wallet_address') as string}
                        onChange={(value) => handleInputChange('wallet_address', value)}
                    />
                </div>
            }
        </div>
    )
}

const i18n = [
    'Vadato.Donators.Create.Form.Connect.Wallet.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Button.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.Or.Label',
    'Vadato.Donators.Create.Form.Connect.Wallet.PublicKey.Label',
]

