import React, { ReactElement, useEffect, useRef } from "react"
import ClickAwayListener from "react-click-away-listener";
import './css/dropdown.css'
import ReactDOM from "react-dom";
import { useFloating } from "@floating-ui/react";
import { arrow, flip, offset, ReferenceType, shift, size, UseFloatingProps } from "@floating-ui/react-dom";

export const Dropdown = (props: DropdownProps) => {

    const arrowRef = useRef(null);
    const { x, y, strategy, refs } = useFloating({
        placement: 'top-start',
        ...props,
        middleware: [
            offset(10),
            arrow({
                element: arrowRef,
            }),
            flip(),
            shift(),
            size({
                apply({ availableWidth, availableHeight, elements }) {
                    // Do things with the data, e.g.
                    Object.assign(elements.floating.style, {
                        maxWidth: `${availableWidth}px`,
                        maxHeight: `${availableHeight - 10}px`,
                    });
                },
            }),
        ]
    });

    useEffect(() => {
        if (props.anchorEl) {
            refs.setReference(props.anchorEl)
        }
    }, [props.anchorEl])

    return ReactDOM.createPortal(
        (
            <div
                className="dropdown"
                ref={refs.setFloating}
                style={{
                    position: strategy,
                    top: y ?? 0,
                    left: x ?? 0,
                    minWidth: props.anchorEl?.offsetWidth,
                    overflow: 'auto'
                }}>
                {
                    props.anchorEl && props.arrow &&
                    <div className="dropdown-arrow" ref={arrowRef} />
                }
                {
                    props.anchorEl && props.children &&
                    <ClickAwayListener onClickAway={() => props.onClose?.()}>
                        <div className="dropdown-content">
                            {
                                React.cloneElement(props.children)
                            }
                        </div>
                    </ClickAwayListener>
                }
            </div>
        ),
        document.body
    )

}

export type DropdownProps = Partial<UseFloatingProps<ReferenceType>> & {
    anchorEl?: HTMLElement
    arrow?: boolean
    children?: ReactElement
    onClose?: () => void
}
