import { useCallback, useMemo } from "react"

export const useCryptoConvertion = () => {
    
    const data = useMemo(() => {
        return {
            'BTC': 22072.91,
            'LTC': 91.4,
            'ETH': 1553.26,
            'XPR': 0.36
        }
    }, [])
    
    const convertToEuro = useCallback((amont: number, currency: string) => {
        const currencyAmont = data[currency]

        return (amont || 0) * currencyAmont
    }, [])
    
    const convertToCrypto = useCallback((amont: number, currency: string) => {
        const currencyAmont = data[currency]

        return (amont || 0) / currencyAmont
    }, [])

    return {
        convertToEuro,
        convertToCrypto
    }

}

