
import { TDonatorCreate } from "@hooks/data"
import { useFormData } from "@hooks/formdata"
import { useTranslations } from "@hooks/translation"
import moment from "moment"

export const useCreationFormData = (defaultValue?: Partial<TDonatorCreate>, required?: Array<string>, readonly?: boolean) => {

    const [
        MAJORITY_ERROR,
        MAIL_ERROR
    ] = useTranslations(i18n)

    return useFormData<Partial<TDonatorCreate>>({
        id: 'Vadato.Creation.FormData',
        formData: {
            country_code: '33',
            dob: moment('1990/11/11').format('YYYY-MM-DD'),
            nationality: 'France',
            ...defaultValue
        },
        alpha: [
            'first_name',
            'last_name'
        ],
        readonly,
        required: required || [
            'first_name',
            'last_name',
            'title',
            'address.address',
            'address.postal_code',
            'address.city',
            'dob',
            'crypto_coin',
            'nationality',
            'phone',
            'country_code',
            'wallet_address',
            'email',
            'amount',
        ],
        validate: (name, value, formData) => {
            
            if (name === 'dob' && moment(value).isAfter(moment().subtract(18, 'years'))) {
                return MAJORITY_ERROR
            }
            if (name === 'email' && !/^\s*[a-zA-Z0-9\._%+-]+@[a-zA-Z0-9\.-]+\.[a-zA-Z]{2,4}\s*$/.test(value)) {
                return MAIL_ERROR
            }

            return ''
        },
    })
}

const i18n = [
    'Vadato.Donators.Create.Form.Birthday.Majority.Error',
    'Vadato.Donators.Create.Form.Email.Invalid.Error'
]
