import { useDonatorCreateMutation } from "@hooks/data"
import { useTranslations } from "@hooks/translation"
import moment from "moment"
import React from "react"
import { useCallback, useEffect, useMemo, useState } from "react"
import { ButtonSubmit, IconButton } from ".."
import { CreationCryptoAddress } from "./CreationCryptoAddress"
import { CreationDonationForm } from "./CreationDonationForm"
import { CreationDonatorForm } from "./CreationDonatorForm"
import { CreationSaveSuspense } from "./CreationSaveSuspense"
import { CreationWalletForm } from "./CreationWalletForm"
import { useCreationFormData } from "./hooks/useCreationFormData"
import { useCurrentPayment } from "./hooks/useCurrentPayment"

export const AssociationCreationFormWizard = () => {

    const [
        CONTINUE
    ] = useTranslations(i18n)
    const creationMutation = useDonatorCreateMutation()
    const { currentPayment, isExpired, setPayment, paymentResult } = useCurrentPayment()
    const [waitScreen, setWaitScreen] = useState(false)
    const [step, setStep] = useState(currentPayment ? 2 : 0)
    const { formData, isValid, resetErrors, errors } = useCreationFormData({}, requireds[step])

    const handleBackClick = useCallback(() => {
        setStep((v) => {
            if (v === 0) {
                return 0
            }
            return v - 1
        })
    }, [])

    const handleNextClick = () => {
        if (step > 2 && !isValid()) {
            return;
        }
        if (step === 1) {
            creationMutation.mutate({
                ...formData,
                phone: formData.phone?.replace(/\s/g, ''),
                dob: moment(formData.dob).format('MM/DD/YYYY'),
                amount: parseFloat(formData.amount?.toString()!)
            }, {
                onSuccess: (data) => {
                    setStep((v) => v + 1)
                    setPayment(data)
                }
            })
            return;
        }

        if (step === 2) {
            window.open(currentPayment?.coinhouseData.urlPayment, '_blank')
            setWaitScreen(true)
            return;
        }

        setStep((v) => v + 1)
    }
   

    if (waitScreen) {
        return (
            <div className="h-full bg-secondary">
                <div className="h-screen flex justify-center items-center">
                    <CreationSaveSuspense
                        amont={currentPayment?.coinhouseData.order.amount}
                        cryptoCurrency={currentPayment?.coinhouseData.order.currency}
                        isError={isExpired || paymentResult?.message === 'error'}
                        isLoading={!isExpired && paymentResult?.message === 'wait'}
                        isSuccess={!isExpired && paymentResult?.message === 'success'}
                    />
                </div>
            </div>
        )
    }

    return (
        <div className="bg-secondary flex flex-col gap-6 w-full pt-32 pb-5 py-7 px-5 min-h-screen">
            <div>
                <IconButton
                    disabled={[0, 2].includes(step)}
                    icon={<img src="/assets/img/icons/arrow-left-circle.svg" />}
                    onClick={handleBackClick}
                />
            </div>
            <div>
                {
                    step === 0 &&
                    <CreationDonatorForm />
                }

                {
                    step === 1 &&
                    <div className="flex flex-col gap-20">
                        <CreationWalletForm />
                        <CreationDonationForm />
                    </div>
                }

                {
                    step === 2 &&
                    <div className="flex flex-col gap-20">
                        <CreationCryptoAddress
                            {...(currentPayment)!}
                            hideButton
                        />

                    </div>
                }
            </div>
            <div className="mt-5">
                <ButtonSubmit
                    isSubmit={creationMutation.isLoading}
                    className="btn-primary w-full justify-center"
                    onClick={handleNextClick}>
                    {CONTINUE}
                </ButtonSubmit>
            </div>
        </div>
    )
}

const requireds = [
    [
        'first_name',
        'last_name',
        'title',
        'address',
        'dob',
        'nationality',
        'phone',
        'country_code',
        'email',
    ],
    [
        'amount',
        'crypto_coin',
        'wallet_address',
    ]
]

const i18n = [
    "Vadato.Donators.Create.Continue.Button.Label",
]

