import React from 'react'
import { Input, InputProps } from "./Input"

export const Civility = (props: CivilityProps) => {
    return (
        <Input {...props} readonly/>
    )
}

Civility.defaultProps = {
    suggestions: [
        {label: "Mme.", value: "Mme."},
        {label: "Mlle.", value: "Mlle."},
        {label: "M.", value: "M."}
    ]
}

export type CivilityProps = InputProps