import { TDonatorCreateResponse } from "@hooks/data"
import { useTranslations } from "@hooks/translation"
import moment from "moment"
import React from "react"
import { Button, PAYMENT_LIMIT } from ".."
import { Timer } from "./Timer"

export const CreationCryptoAddress = (props: CreationCryptoAddressProps) => {

    const [
        LASTSTEP_TITLE,
        CRYPTOADRESS_LABEL,
        CRYPTOADRESS_WARNING,
        CRYPTOADRESS_EXPIRED,
        BUTTON_SEND_DONATION
    ] = useTranslations(i18n)

    return (
        <div className="w-full flex justify-center">
            <div className="flex flex-col gap-6 max-w-[560px]">
                <h4 className="font-bold text-center md:text-left">{LASTSTEP_TITLE}</h4>


                <div className="flex flex-col gap-2">
                    <label>{CRYPTOADRESS_LABEL.replace('$1', props.coinhouseData?.order.currency || '')}</label>
                    <div className="px-[15px] py-[15px] bg-secondary">
                        <p>{props.coinhouseData?.order.customerAddress}</p>
                    </div>
                </div>

                <div className="flex flex-col">
                    <h6 className="text-center md:text-left text-danger font-bold">
                        {CRYPTOADRESS_WARNING}
                        <Timer
                            value={
                                moment(props.coinhouseData?.order.dateCreated!)
                                    .add(PAYMENT_LIMIT, 'minutes')
                                    .format('x')
                            }
                            onRenderDuration={(duration) => {
                                return `
                                    ${duration.minutes() ? `${duration.minutes()} minutes` : ''} 
                                    ${duration.seconds() ? `${duration.seconds()} secondes` : ''} 
                                `
                            }}
                        />
                    </h6>

                    <p className="text-center md:text-left text-[12px]">{CRYPTOADRESS_EXPIRED}</p>
                </div>

                {
                    props.hideButton !== true &&
                    <div className="flex justify-center mt-[100px]">
                        <a href={props.coinhouseData?.urlPayment} target="_blank">
                            <Button
                                label={BUTTON_SEND_DONATION}
                                className="btn-primary"
                            />
                        </a>
                    </div>
                }
            </div>
        </div>
    )
}

type CreationCryptoAddressProps = Partial<TDonatorCreateResponse> & {
    hideButton?: boolean
}

const i18n = [
    'Vadato.Donators.Create.LastStep.Title',
    'Vadato.Donators.Create.LastStep.CryptoAdress.Label',
    'Vadato.Donators.Create.LastStep.CryptoAdress.Warning',
    'Vadato.Donators.Create.LastStep.CryptoAdress.Expired.Warning',
    "Vadato.Donators.Create.LastStep.SendMyDonation.Button.Label"
]
