import { useGenericQuery } from "@hooks/query"
import { useAjax } from "@hooks/useAjax"
import _ from "lodash"

export const useDonatorOrderStatusQuery = (id?: string, interval: number = 20) => {
    const ajax = useAjax()
    return useGenericQuery<DonatorOrderStatusResponse>([`Donator.Order.${id}`, id], async () => {
        try {
            const res = await ajax.get(`/donator/v1/payment/status?order_id=${id}`)
            return res.data
        } catch (e) {
            return { message: 'error' }
        }
    }, { staleTime: interval, enabled: id !== undefined })
}

export type DonatorOrderStatusResponse = {
    message: string
}
