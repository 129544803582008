import { useGenericQuery } from "@hooks/query/useGenericQuery"
import { useAjax } from "@hooks/useAjax"
// import { useAjaxToken } from ".."

export const useRuntimeEnv = (suspense?: boolean, enabled?: boolean) => {

    // const { data, invalidateQuery } = useGraphqlQuery<RuntimeEnvQuery, TSessionUser>(new RuntimeEnvQuery(), { suspense, enabled })
    const ajax = useAjax()
    // const [token] = useAjaxToken()
    const {data} =  useGenericQuery('identity_user', async () => {
        // For now we do not have "me" endpoint
        // return token
        try {
            const res = await ajax.get('/auth/v1/me')
            return res.data
        } catch (error) {
            return null
        }
    }, {suspense: true})

    return {
        runtimeEnv: data as any
        // invalidateQuery
    }
    
}
