import { TDonatorCreateResponse, useDonatorOrderStatusQuery } from "@hooks/data"
import moment from "moment"
import { useCallback, useEffect, useMemo, useState } from "react"

export const useCurrentPayment = () => {


    const defaultCurrentPayment = useMemo((): TDonatorCreateResponse | undefined => {
        const item = localStorage.getItem('order')
        if (!item) {
            return undefined
        }
        try {
            return JSON.parse(item) as TDonatorCreateResponse
        } catch (e) {
            return undefined
        }
    }, [localStorage])

    
    const [currentPayment, setCurrentPayment] = useState(defaultCurrentPayment)
    const { data: paymentResult } = useDonatorOrderStatusQuery(currentPayment?.coinhouseData.order.id)

    const isExpired = useMemo(() => {
        if (!currentPayment) {
            return true;
        }
        
        if (paymentResult?.message !== 'wait') {
            return true
        }

        return moment(currentPayment.coinhouseData.order.dateCreated)
            .add(PAYMENT_LIMIT, 'minutes')
            .diff(moment(), 'seconds') <= 0
    }, [currentPayment, paymentResult])

    const setPayment = useCallback((data: TDonatorCreateResponse) => {
        localStorage.setItem('order', JSON.stringify(data))
        setCurrentPayment(data)
    }, [])

    const clearPayment = useCallback(() => {
        localStorage.removeItem('order')
        setCurrentPayment(undefined)
    }, [])

    
    useEffect(() => {
        if (isExpired) {
            clearPayment()
        }
    }, [isExpired])

    return {
        currentPayment,
        setPayment,
        isExpired,
        clearPayment,
        paymentResult
    }
}

export const PAYMENT_LIMIT = 20
