import { useDonatorCreateMutation } from "@hooks/data"
import moment from "moment"
import React from "react"
import { useState } from "react"
import { Dialog } from ".."
import './css/creation-form.css'
import { CreationCryptoAddress } from "./CreationCryptoAddress"
import { CreationDonationForm } from "./CreationDonationForm"
import { CreationDonatorForm } from "./CreationDonatorForm"
import { CreationSaveSuspense } from "./CreationSaveSuspense"
import { CreationWalletForm } from "./CreationWalletForm"
import { useCreationFormData } from "./hooks/useCreationFormData"
import { useCurrentPayment } from "./hooks/useCurrentPayment"
import { useAssociationColorValue } from "./hooks/useAssociationColorValue"

export const AssociationCreationForm = (props: AssociationCreationFormProps) => {

    const creationMutation = useDonatorCreateMutation()
    const { formData, isValid } = useCreationFormData(undefined, undefined, props.readonly)
    const { currentPayment, isExpired, setPayment, paymentResult } = useCurrentPayment()
    const [showDialog, setShowDialog] = useState(currentPayment !== undefined)

    const handleCreationSubmit = () => {
        if (isValid()) {
            creationMutation.mutate({
                ...formData,
                phone: formData.phone?.replace(/\s/g, ''),
                dob: moment(formData.dob).format('MM/DD/YYYY'),
                amount: parseFloat(formData.amount?.toString()!)
            }, {
                onSuccess: (data) => {
                    setPayment(data)
                    setShowDialog(true)
                }
            })
        }
    }

    
    if (currentPayment || showDialog) {
        return (
            <div className="h-full bg-secondary">
                <div className="h-screen flex justify-center items-center">
                    <CreationSaveSuspense
                        amont={currentPayment?.coinhouseData.order.amount}
                        cryptoCurrency={currentPayment?.coinhouseData.order.currency}
                        isError={isExpired || paymentResult?.message === 'error'}
                        isLoading={!isExpired && paymentResult?.message === 'wait'}
                        isSuccess={!isExpired && paymentResult?.message === 'success'}
                    />
                </div>

                <Dialog
                    open={showDialog}
                    className="max-w-[720px] flex justify-center"
                    onClose={() => setShowDialog(false)}>
                    <CreationCryptoAddress {...(currentPayment)!} />
                </Dialog>
            </div>
        )
    }

    return (
        <div className="flex flex-col gap-4">
            <div className="bg-secondary  py-7 px-12">
                <CreationDonatorForm />
            </div>

            <div className="bg-secondary  py-7 px-12">
                <CreationWalletForm />
            </div>


            <div className="bg-secondary  py-7 px-12 mb-4">
                <CreationDonationForm
                    hideSubmit={props.readonly}
                    isSubmit={creationMutation.isLoading}
                    onSubmitClick={handleCreationSubmit}
                />
            </div>
        </div>
    )
}

export type AssociationCreationFormProps = {
    readonly?: boolean
}

