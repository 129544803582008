
import { useTranslation } from "@hooks/translation"
import { useBreakpoints } from "@hooks/utils"
import React from "react"
import { useMemo } from "react"
import { useCryptoList } from ".."

export const CreationSaveSuspense = (props: CreationSaveSuspenseProps) => {

    const t = useTranslation()
    const { xs, md, lg } = useBreakpoints()

    const cryptos = useCryptoList()
    const icon = useMemo(() => {
        if (props.isLoading) {
            return !md
                ? '/assets/img/icons/loading.svg'
                : '/assets/img/icons/loading-circle.svg'
        }
        if (props.isSuccess) {
            return '/assets/img/icons/success.svg'
        }
        if (props.isError) {
            return '/assets/img/icons/error.svg'
        }
        return ''
    }, [props, md])

    const cryptoIcon = useMemo(() => {
        return cryptos.find((item) => item.value === props.cryptoCurrency)?.icon
    }, [cryptos, props])

    const state = useMemo(() => {
        if (props.isLoading) {
            return 'LOADING'
        }
        if (props.isSuccess) {
            return 'SUCCESS'
        }
        if (props.isError) {
            return 'ERROR'
        }
        return ''
    }, [props])

    return (
        <div className="md:w-[480px] md:max-w-[480px] flex flex-col items-center py-8 px-5 md:bg-white rounded-[30px]">
            <img
                src={icon}
                className=" h-[65px] mt-[105px]"
            />
            <h4 className="font-bold mt-[30px]  hidden md:block">{t('Vadato.Donators.Create.Loading.Message')}</h4>

            {
                (!md || (md && props.isLoading)) &&
                <>
                    <h4 className="font-bold mt-[30px]  md:hidden">{t('Vadato.Donators.Create.Loading.Message')}</h4>
                    <div className="relative w-full md:w-auto mt-[30px] py-5 md:py-0">
                        <div className="absolute z-0 bg-white left-[-30px] right-[-25px] top-0 bottom-0">

                        </div>
                        <div className="flex z-10 relative  justify-center items-center gap-4">
                            <h2 className="text-primary text-[64px]">
                                {props.amont}
                            </h2>
                            <img src={cryptoIcon} className="h-[40px] md:h-[55px]" />
                        </div>
                    </div>
                </>

            }

            <h4 className={`font-bold mt-[40px] ${props.isLoading ? '' : 'hidden md:block'}`}>{t(`Vadato.Donators.Create.Loading.State.${state}.Label`)}</h4>
            {
                !props.isLoading &&
                <h4 className="font-bold mt-[40px] text-center md:hidden">{t(`Vadato.Donators.Create.Loading.State.Mobile.${state}.Label`)}</h4>
            }
            <p className="mt-[35px] text-center">
                {t(`Vadato.Donators.Create.Loading.State.${state}.Message`)}
            </p>
        </div>
    )
}

export type CreationSaveSuspenseProps = {
    isLoading?: boolean
    isSuccess?: boolean
    isError?: boolean
    amont?: number
    cryptoCurrency?: string
}
