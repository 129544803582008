import { Dropdown } from "@components/dropdown"
import { useTranslation } from "@hooks/translation"
import { useCryptoConvertion } from "@hooks/utils"
import React, { createRef, HTMLAttributes, useCallback, useState } from "react"
import { IconButton } from ".."
import { BaseControl, BaseControlProps } from "./BaseControl"
import { CryptoList } from "./components/CryptoList"
import { CryptoPad } from "./CryptoPad"

export const CryptoCurrency = ({ readonly, value, ...props }: CryptoCurrencyProps) => {

    const [currencyButtonRef, setCurrencyButtonRef] = useState<HTMLDivElement>()
    const [cryptoPadContainerRef, setCryptoPadContainerRef] = useState<HTMLDivElement>()
    const containerRef = createRef<HTMLDivElement>()
    const t = useTranslation()
    const { convertToEuro } = useCryptoConvertion()

    const handlePadClick = useCallback((val: string) => {

        if (val === '*') {
            setCryptoPadContainerRef(undefined)
            return;
        }

        let nextVal = value?.value || ''
        if (val === '\b') {
            nextVal = nextVal.substring(0, nextVal.length - 1)
        } else {
            nextVal += val
        }
        props.onChange?.({ ...value, value: nextVal })

    }, [value])

    return (
        <BaseControl {...props}>
            <div className="flex flex-col gap-2">
                <div
                    className="flex gap-2 h-[80px]"
                    ref={containerRef}>
                    <div
                        className="flex flex-col justify-center gap-3 px-2 bg-white border-[1px] border-primary rounded-[10px]"
                        onClick={(e) => setCurrencyButtonRef(containerRef.current!)}>
                        <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-up.svg" className="h-4" />} />
                        <IconButton className="!p-[5px]" icon={<img src="/assets/img/icons/chevron-down.svg" className="h-4" />} />
                    </div>
                    <div
                        className="flex flex-1 gap-6 items-center py-5 px-4 bg-white w-full border-[1px] border-primary rounded-[10px] cursor-pointer"
                        onClick={(e) => {
                            if (!value?.currency) {
                                setCurrencyButtonRef(containerRef.current!)
                                return;
                            }
                            setCryptoPadContainerRef(containerRef.current!)
                        }}>
                        <div className="w-9">
                            {
                                value?.currency &&
                                <img src={`/assets/img/icons/${value?.currency}-circle.svg`} className="w-full" />
                            }
                        </div>
                        <h2 className="text-primary">
                            {value?.value}
                        </h2>
                    </div>
                </div>
                {
                    !value?.currency &&
                    <p>{t('Vadato.Donators.Create.Form.SelectCrypto.Label')}</p>
                }
                {
                    value?.currency &&
                    <p className="text-right">{value.value} {value.currency} = {convertToEuro(parseFloat(value.value || '0'), value.currency)} €</p>
                }
            </div>

            <Dropdown anchorEl={currencyButtonRef} open={currencyButtonRef !== undefined} onClose={() => setCurrencyButtonRef(undefined)}>
                <CryptoList value={value?.currency} onClick={(val) => {
                    props.onChange?.({ ...value, currency: val })
                    setCurrencyButtonRef(undefined)
                    setCryptoPadContainerRef(containerRef.current!)
                }} />
            </Dropdown>

            <Dropdown anchorEl={cryptoPadContainerRef} open={cryptoPadContainerRef !== undefined} onClose={() => setCryptoPadContainerRef(undefined)}>
                <CryptoPad onClick={handlePadClick} />
            </Dropdown>
        </BaseControl>
    )
}

CryptoCurrency.defaultValue = {
    value: {}
}

export type CryptoCurrencyProps = BaseControlProps & Omit<HTMLAttributes<HTMLTextAreaElement>, 'onChange'> & {
    value?: CryptoCurrencyValue
    onChange?: (value?: CryptoCurrencyProps['value']) => void
}

export type CryptoCurrencyValue = { currency?: string, value?: string }
